.component-select-menu::-webkit-scrollbar {
  width: 4px;
}

.component-select-menu::-webkit-scrollbar-thumb {
  background: #D7D7D7;
}

.component-select-menu::-webkit-scrollbar-track {
  background: #F3F3F3;
}
