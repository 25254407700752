.hexagon-section {
  flex: 1;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
      -45deg,
      #FFB5B5,
      #FFE4B5,
      #B5FFB5,
      #B5E4FF,
      #E4B5FF
  );
  background-size: 300% 300%;
  animation: gradientShift 20s ease infinite;
}

#hexagonCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
  display: block !important;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 0%;
    background-size: 200% 200%;
  }
  25% {
    background-position: 100% 0%;
    background-size: 300% 300%;
  }
  50% {
    background-position: 100% 100%;
    background-size: 200% 200%;
  }
  75% {
    background-position: 0% 100%;
    background-size: 300% 300%;
  }
  100% {
    background-position: 0% 0%;
    background-size: 200% 200%;
  }
}
